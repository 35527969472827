<template>
  <div class="">
    <el-dialog
      :visible.sync="dialogVisible"
      width="700px"
      :show-close="false"
      :modal-append-to-body="false"
      :lock-scroll="false"
    >
      <div class="details">
        <span class="closeicon" @click="closebox()"
          ><i class="el-icon-error"></i
        ></span>
        <div class="wb-tit font18">延期</div>
        <div class="updatabox">
          <el-form
            :model="infoValidateForm"
            ref="infoValidateForm"
            label-width="130px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="选择延期日期："
              prop="endDate"
              :rules="[
                { required: true, message: '请选择延期日期', trigger: 'blur' },
              ]"
            >
              <!-- <el-date-picker type="date" placeholder="起" v-model="infoValidateForm.start" style="width:200px;"></el-date-picker>
								<span style="margin: 0 15px;"></span>
							<el-date-picker type="date" placeholder="请选择日期" v-model="infoValidateForm.endDate" style="width:200px;"></el-date-picker> -->
              <el-date-picker
                v-model="infoValidateForm.endDate"
                type="datetime"
                placeholder="请选择择延日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item
              label="延期理由："
              prop="reasons"
              :rules="[
                { required: true, message: '请选择延期理由', trigger: 'blur' },
              ]"
            >
              <el-select
                v-model="reasonList"
                multiple
                placeholder="请选择"
                style="width: 430px"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="更多反馈：" prop="reasonDescription">
              <el-input
                type="textarea"
                :autosize="{ minRows: 8, maxRows: 10 }"
                show-word-limit
                placeholder="请输入反馈内容"
                v-model="infoValidateForm.reasonDescription"
                style="width: 430px"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <div class="apply_tishi">
                <div class="p-img font16">
                  <i class="el-icon-warning"></i>
                </div>
                <div class="p-cont font14">
                  延期需与技术者协商，意见达成一致后才可向平台发起延期申请
                </div>
              </div>
            </el-form-item>
            <el-form-item
              ><el-button
                type="primary"
                class="btn"
                @click="submitForm('infoValidateForm')"
                >确认提交</el-button
              ></el-form-item
            >
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
export default {
  data() {
    return {
      dialogVisible: false,
      infoValidateForm: {
        endDate: "",
        reasonDescription: "",
        reasons: "",
        userWorkIds: "",
      },
      options: [],
      reasonList: [],
    };
  },
  activated() {
    this.getOption();
  },
  mounted() {
    Bus.$on("delaystuts", (data) => {
      // console.log('退场接受到', data);
      this.dialogVisible = data.show;
      this.infoValidateForm.userWorkIds = data.id;
    });
  },
  methods: {
    getOption() {
      this.$api
        .getdict("get", { code: "company_delay_reasons" })
        .then((res) => {
          let list = [];
          res.data.map((item) => {
            let obj = {
              value: item.id,
              label: item.name,
            };
            list.push(obj);
          });
          this.options = list;
        });
    },
    closebox() {
      this.dialogVisible = false;
      // Bus.$emit('reportdstuts', false)
    },
    submitForm(formName) {
      this.infoValidateForm.reasons = this.reasonList.join(",");
      let config = JSON.parse(JSON.stringify(this.infoValidateForm));
      let y = this.infoValidateForm.endDate.getFullYear();
      let m = this.infoValidateForm.endDate.getMonth() + 1;
      let d = this.infoValidateForm.endDate.getDate();
      let h = this.infoValidateForm.endDate.getHours();
      let f = this.infoValidateForm.endDate.getMinutes();
      let s = this.infoValidateForm.endDate.getSeconds();
      config.endDate = y + "-" + m + "-" + d + " " + h + ":" + f + ":" + s;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$api.delay("post", config).then(() => {
            this.dialogVisible = false;
            window.location.reload();

            this.$message({
              message: "操作成功",
              type: "success",
            });
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
</style>
<style lang="less" scoped>
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
.details {
  padding-bottom: 40px;
  .wb-tit {
    padding: 17px 0;
    text-align: center;
    background-color: #f3f3f3;
    color: #000000;
    font-weight: 600;
  }
}

.updatabox {
  .demo-ruleForm {
    padding: 50px 80px 0px 50px;
    /deep/ .el-input__inner,
    /deep/ .el-textarea__inner {
      border-radius: 0px;
    }
    /deep/ .el-button {
      border-radius: 0px;
      width: 430px;
      margin-top: 50px;
      background: #00bcff;
      border: none;
    }
    .apply_tishi {
      display: flex;
      align-items: flex-start;
      width: 450px;
      .p-img {
        padding: 2px 8px 0 8px;
        color: #ff4444;
        display: flex;
        align-items: flex-start;
      }
      .p-cont {
        display: flex;
        flex: 1;
        color: #666666;
        line-height: 1.5;
        text-align: left;
      }
    }
  }
}
</style>
