<template>
  <div class="onjob">
    <el-card class="box-card condition-box">
      <el-row style="padding: 0 10px">
        <el-col :span="14" style="display: none">
          <div class="header-left">
            <div class="font14">面试时间查询：</div>
            <el-date-picker
              type="date"
              placeholder="起"
              v-model="formInline.startTime"
              style="width: 182px"
            ></el-date-picker>
            <span style="margin: 0 15px"></span>
            <el-date-picker
              type="date"
              placeholder="止"
              v-model="formInline.endTime"
              style="width: 182px"
            ></el-date-picker>
          </div>
        </el-col>
        <el-col :span="10">
          <div class="header-right" style="text-align: left">
            <el-select
                v-model="jobId"
                placeholder="全部职位"
                @change="selectJob"
                style="width: 130px; margin-right: 50px"
            >
              <el-option
                  v-for="item2 in positionoptions"
                  :key="item2.value"
                  :label="item2.label"
                  :value="item2.value"
              >
              </el-option>
            </el-select>
            </div>
        </el-col>
        <el-col :span="10" style="float: right">
          <div class="header-right" style="text-align: right">
            <el-select
                v-model="pageInfo.status"
                placeholder="在职状态"
                @change="selectStatus"
                style="width: 130px"
            >
              <el-option
                  v-for="item2 in zzoptions"
                  :key="item2.value"
                  :label="item2.label"
                  :value="item2.value"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <!-- <div class="handshake-tishi">您有5条待面试 ，3条面试已拒绝</div> -->
    <!-- 无数据 -->
    <div class="nodata" v-if="userList != null && userList.length == 0">
      <img src="../../assets/images/person/p-nodata.png" />
      <div class="font16">当前无数据</div>
    </div>
    <el-card class="p-list" v-for="(itemes, index) in userList" :key="index">
      <div class="process" @click="todetail(itemes)">
        <div class="p-left">
          <div class="pl-one">
            <div class="p-picurl">
              <img
                :src="'https://freemen.work/user/file/view/' + itemes.avatar"
              />
              <span
                class="bofang font20"
                v-if="itemes.resUrl"
                @click.stop="videoshow(itemes)"
              >
                <!-- <i class="el-icon-video-play"></i> -->
                <img src="../../assets/images/common/bofangicon.png" />
              </span>
            </div>
            <div class="p-info">
              <div class="pn-name">
                <div class="namemain">
                  <span class="font22">{{ itemes.displayName }}</span>
                  <img
                    src="../../assets/images/person/boy.png"
                    v-if="itemes.sex == 1"
                  />
                  <img src="../../assets/images/person/girl.png" v-else />
                  <span class="font14 Free">Free</span>
                  <span class="font14 Free" v-if="itemes.status == 0"
                    >在职</span
                  >
                  <span class="font14 tui" v-if="itemes.status == 1"
                    >已退场</span
                  >
                  <span class="font14 tui" v-if="itemes.status == 2"
                    >已评价</span
                  >
                </div>
                <div class="score font14">
                  <span>{{ itemes.score }}</span>
                </div>
              </div>
              <div class="beizhu font14">
                <!-- {{itemes.expYear}}年 | {{itemes.educationType ? itemes.educationType : '不限'}} | {{new Date().getYear() - new Date(itemes.birthday).getYear()}}岁 |{{itemes.jobLevelNames}} -->
                {{ itemes.expYear }}年 |
                {{
                  new Date().getYear() -
                  new Date(itemes.birthday).getYear() -
                  (new Date().getMonth() > new Date(itemes.birthday).getMonth()
                    ? 0
                    : 1)
                }}岁 | {{ itemes.jobLevelNames }}
              </div>
            </div>
          </div>
          <div class="pl-two font16">应聘岗位：{{ itemes.companyJobName }}</div>
        </div>
        <el-divider direction="vertical"></el-divider>
        <div class="p-right font14">
          <!-- <div class="">杭州最田源科技有限公司</div>
					<div class="">联系人：含含</div>
					<div class="">联系电话：18814828888</div>
					<div class="">面试类型：线上面试</div>
					<div class="">面试时间：2021-04-20 10:00</div> -->
          <div
            style="
              display: flex;
              margin-bottom: 10px;
              justify-content: space-between;
            "
            v-if="itemes.status != 2"
          >
            <div></div>
            <el-button
              type="primary"
              class="yy"
              style="width: 100px"
              @click.stop="operation(itemes)"
              >操作</el-button
            >
          </div>
          <div class="">
            任职时间：{{ datatime(itemes.startDate) }} ~
            {{ datatime(itemes.endDate) ? datatime(itemes.endDate) : "/" }}
          </div>
        </div>
        <div class="zhezhao" v-if="itemes.moreShow">
          <div class="zzbg"></div>
          <div class="caouo" @click.stop="operation(itemes)">
            <img
              v-if="itemes.status == 0"
              src="../../assets/images/person/tuichang.png"
              @click.stop="tuichang(itemes)"
            />
            <img
              v-if="itemes.status == 0"
              src="../../assets/images/person/yanqi.png"
              @click.stop="yanqi(itemes)"
            />
            <img
              v-if="itemes.status == 0"
              src="../../assets/images/person/jinggao.png"
              @click="jinggao(itemes)"
            />
            <img
              v-if="itemes.status == 1"
              src="../../assets/images/person/pingjia.png"
              @click.stop="pingjia(itemes)"
            />
          </div>
        </div>
      </div>
    </el-card>
    <div class="nextpage" @click="getMoreList" v-if="moreBtn">点击加载更多</div>
    <!-- 分页 -->
    <!-- <div class="paginate" style="position: relative;margin-top: 100px;">
			<el-pagination
				@current-change="handleCurrentChange"
				:current-page="pageInfo.pageNum"
				:page-size="pageInfo.pageSize"
				layout="total, prev, pager, next, jumper"
				:total="pageInfo.count"
			></el-pagination>
		</div> -->

    <!-- 提交成功 -->
    <el-dialog
      :visible.sync="upDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div class="updown_box">
        <div class="ub-img">
          <img src="../../assets/images/common/gou-chenggong.png" />
        </div>
        <div class="ub-text font22">上架成功</div>
      </div>
    </el-dialog>
    <!-- 退场 -->
    <walkoff></walkoff>
    <!-- 延期 -->
    <delay></delay>
    <!-- 警告 -->
    <warning></warning>
    <!-- 评价 -->
    <evaluate></evaluate>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
import delay from "/src/components/person/delay.vue"; //延期
import evaluate from "/src/components/person/evaluate.vue"; //评价
import walkoff from "/src/components/person/walkoff.vue"; //退场
import warning from "/src/components/person/warning.vue"; //警告
export default {
  components: {
    walkoff,
    delay,
    warning,
    evaluate,
  },
  data() {
    return {
      formInline: {
        zzstuts: "全部",
        startTime: "",
        endTime: "",
        type: "",
        stuts: "",
        pageNum: 1,
        pageSize: 10,
      },
      pageInfo: {
        pageNo: 1,
        pageSize: 10,
        status: "",
      },
      jobId: "",
      moreBtn: false,
      // 全部状态
      positionoptions: [{ value: "全部", label: "全部职位" }],
      zzoptions: [
        { value: "", label: "全部人员" },
        { value: 0, label: "在职人员" },
        { value: 1, label: "退场人员" },
        // { value: 2, label: "已评价人员" },
      ],
      userList: null,
      upDialogVisible: false, //提交成功弹窗
    };
  },
  activated() {
    this.getJobList();
  },
  methods: {
    datatime(value) {
      var data = new Date(value);
      var month =
        data.getMonth() < 9 ? "0" + (data.getMonth() + 1) : data.getMonth() + 1;
      var date = data.getDate() <= 9 ? "0" + data.getDate() : data.getDate();
      return data.getFullYear() + "-" + month + "-" + date;
    },
    getJobList() {
      //获取职位列表
      this.$api.joblist("get").then((res) => {
        this.positionoptions = [{ value: "全部", label: "全部职位" }];
        res.data.map((item) => {
          // if (item.status === 1) {
            let obj = {
              label: item.jobName,
              value: item.id,
            };
            this.positionoptions.push(obj);
          // }
          if (this.positionoptions.length > 0) {
            this.jobId = this.positionoptions[0].value;
          }
        });
        this.getList();
      });
    },
    getList() {
      this.$api.getWorkList("get", this.pageInfo).then((res) => {
        if (this.jobId == "全部") {
          if (this.pageInfo.pageNo == 1) {
            this.userList = res.data;
          } else {
            this.userList = this.userList.concat(res.data);
          }
          // this.userList = this.userList.concat(res.data);
        } else {
          let arr = [];
          res.data.map((item) => {
            if (item.companyJobId == this.jobId) {
              arr.push(item);
            }
          });

          // this.userList = this.userList.concat(arr);

          if (this.pageInfo.pageNo == 1) {
            this.userList = arr;
          } else {
            this.userList = this.userList.concat(arr);
          }
        }
        if (res.data.length < 10) {
          this.moreBtn = false;
        } else {
          this.moreBtn = true;
        }
        this.userList.map((item) => {
          item.moreShow = false;
        });
      });
    },
    selectJob() {
      this.pageInfo.pageNo = 1;
      this.userList = [];
      this.getList();
    },
    selectStatus() {
      this.pageInfo.pageNo = 1;
      this.userList = [];
      this.getList();
    },
    todetail(data) {
      this.$resumeDetail.show({
        show: true,
        type: "zaizhi",
        companyJobId: data.companyJobId,
        userJobId: data.userJobId,
        positionoptions: this.positionoptions,
        callback: () => {
          let tempQuery = {
            active: 1,
            companyJobId: data.companyJobId,
            companyJobName: data.companyJobName,
            userJobId: data.userJobId,
            userId: data.userId,
          }
          this.$router.push({ path: "chatmain", query: tempQuery});
        },
      });
    },
    videoshow(data) {
      let config = {
        show: true,
        data: data,
      };
      this.$videoDetail.show(config);
    },
    operation(data) {
      data.moreShow = !data.moreShow;
      this.$forceUpdate();
    },
    handleCurrentChange(val) {
      this.fetchData(val);
    },
    tuichang(data) {
      Bus.$emit("walkoffstuts", {
        show: true,
        id: data.id,
      });
    },
    yanqi(data) {
      Bus.$emit("delaystuts", {
        show: true,
        id: data.id,
      });
    },
    jinggao(data) {
      Bus.$emit("warnstuts", {
        show: true,
        id: data.id,
      });
    },
    pingjia(data) {
      Bus.$emit("evaluatestuts", {
        show: true,
        id: data.id,
        companyJobId: data.companyJobId,
        userId: data.userId,
        userJobId: data.userJobId,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.nodata {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #111111;
  padding: 37px 0 20px 0;
  img {
    width: 256px;
    height: 215px;
    margin-bottom: 20px;
  }
}
.nextpage {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999999;
  cursor: pointer;
  margin-top: 15px;
}
.condition-box {
  // background-color: #ffffff;
  // padding: 22px 0;
  // box-shadow: 0px 1px 29px 17px rgb(0 0 0 / 3%);
  /deep/ .el-input__inner {
    border-radius: 0px;
    // border: none;
    // background-color: #f3f3f3;
  }
  .header-left {
    display: flex;
    align-items: center;
    color: #111111;
  }
  .header-right {
    /deep/ .el-input__inner {
      border-radius: 0px;
      // background-color: #00bcff;
      // border: none;
      // color: #FFFFFF;
    }
  }
}
.handshake-tishi {
  margin-top: 20px;
  background-color: #feecde;
  text-align: center;
  color: #ff5400;
  padding: 14px 0;
  box-shadow: 0px 1px 29px 17px rgb(0 0 0 / 3%);
}
.p-list:hover {
  transform: scale(1.02);
  background-color: rgba(0, 0, 0, 0.001);
}
.p-list {
  margin-top: 20px;
  position: relative;
  .zhezhao {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    .zzbg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgb(255 255 255 / 50%);
    }
    .caouo {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      position: relative;
      img:hover {
        cursor: pointer;
      }
      img {
        height: 100px;
        width: 100px;
        margin: 0 30px;
      }
    }
  }
  .process {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 20px 30px;
    .p-left {
      padding-right: 30px;
      display: flex;
      flex-direction: column;
      flex: 1;
      .pl-one {
        display: flex;
        align-items: center;
        .p-picurl {
          margin-right: 15px;
          position: relative;
          img {
            width: 50px;
            height: 50px;
          }
          .bofang:hover {
            cursor: pointer;
          }
          .bofang {
            position: absolute;
            top: -3px;
            right: -6px;
            // border-radius: 50px;
            // color: #3F3F3F;
            img {
              width: 22px;
              height: 22px;
            }
          }
        }
        .p-info {
          display: flex;
          // align-items: center;
          justify-content: flex-start;
          flex-direction: column;
          flex: 1;
          .pn-name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            line-height: 1.5;
            .namemain {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              img {
                width: 24px;
                height: 24px;
                margin: 0 18px 0 10px;
              }
              .Free {
                background-color: #e9f9ff;
                color: #00bcff;
                padding: 2px 10px;
                margin-right: 6px;
              }
              .tui {
                background-color: #f3f3f3;
                color: #999999;
                padding: 2px 10px;
                margin-right: 6px;
              }
            }
            .score {
              background-color: #00bcff;
              border-radius: 8px 8px 8px 0;
              color: #ffffff;
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .beizhu {
            color: #666666;
          }
        }
      }
      .pl-two {
        color: #111111;
        margin-top: 20px;
      }
    }
    /deep/.el-divider--vertical {
      height: 4em;
      background-color: #f3f3f3;
    }
    .p-right {
      display: flex;
      // align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      color: #666666;
      flex: 1;
      padding-left: 30px;
      line-height: 1.6;
    }
  }
}
.updown_box {
  line-height: 3;
  padding: 40px 0;
  .ub-img {
    text-align: center;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .ub-text {
    text-align: center;
    color: #111111;
  }
}
</style>
