<template>
  <div class="">
    <el-dialog
      :visible.sync="dialogVisible"
      width="700px"
      :show-close="false"
      :modal-append-to-body="false"
      :lock-scroll="false"
    >
      <div class="details">
        <span class="closeicon" @click="closebox()"
          ><i class="el-icon-error"></i
        ></span>
        <div class="wb-tit font18">警告</div>
        <div class="updatabox">
          <el-form
            :model="infoValidateForm"
            ref="infoValidateForm"
            label-width="130px"
            class="demo-ruleForm"
          >
            <el-form-item label="警告内容：" prop="content">
              <el-input
                type="textarea"
                :autosize="{ minRows: 8, maxRows: 10 }"
                show-word-limit
                placeholder="请输入反馈内容"
                v-model="infoValidateForm.reasonDescription"
                style="width: 430px"
              ></el-input>
            </el-form-item>
            <el-form-item
              ><el-button
                type="primary"
                class="btn"
                @click="submitForm('infoValidateForm')"
                >确认提交</el-button
              ></el-form-item
            >
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
export default {
  data() {
    return {
      dialogVisible: false,
      infoValidateForm: {
        reasonDescription: "",
        userWorkIds: "",
        // warnType:'',
        // type:''
      },
      options: [],
    };
  },
  activated() {
    this.getOption();
  },
  mounted() {
    Bus.$on("warnstuts", (data) => {
      // console.log('退场接受到', data);
      this.dialogVisible = data.show;
      this.infoValidateForm.userWorkIds = data.id;
    });
  },
  methods: {
    getOption() {
      this.$api
        .getdict("get", { code: "company_quite_reasons" })
        .then((res) => {
          let list = [];
          res.data.map((item) => {
            let obj = {
              value: item.id,
              label: item.name,
            };
            list.push(obj);
          });
          this.options = list;
        });
    },
    closebox() {
      this.dialogVisible = false;
      // Bus.$emit('reportdstuts', false)
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // this.infoValidateForm.type = this.infoValidateForm.warnType
          this.$api.workWarn("post", this.infoValidateForm).then(() => {
            this.dialogVisible = false;
            this.$message({
              message: "操作成功",
              type: "success",
            });
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
</style>
<style lang="less" scoped>
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
.details {
  padding-bottom: 40px;
  .wb-tit {
    padding: 17px 0;
    text-align: center;
    background-color: #f3f3f3;
    color: #000000;
    font-weight: 600;
  }
}

.updatabox {
  .demo-ruleForm {
    padding: 50px 80px 0px 50px;
    /deep/ .el-input__inner,
    /deep/ .el-textarea__inner {
      border-radius: 0px;
    }
    /deep/ .el-button {
      border-radius: 0px;
      width: 430px;
      margin-top: 50px;
      background: #00bcff;
      border: none;
    }
  }
}
</style>
