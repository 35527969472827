<template>
  <div class="">
    <el-dialog
      :visible.sync="dialogVisible"
      width="700px"
      :show-close="false"
      :modal-append-to-body="false"
      :lock-scroll="false"
    >
      <div class="details">
        <span class="closeicon" @click="closebox()"
          ><i class="el-icon-error"></i
        ></span>
        <div class="wb-tit font18">评价</div>
        <div class="updatabox" v-if="list.length">
          <el-card class="box-card" v-for="(item, index) in list" :key="index">
            <div class="list">
              <div class="l-left">
                <div class="font16">{{ item.name }}</div>
                <div class="font14">{{ item.remark }}</div>
              </div>
              <div class="l-right font14">
                <div class="" @click="change1(index)">
                  <img
                    :src="
                      infoValidateForm.list[index].isRight == 1
                        ? goodIcon2
                        : goodIcon1
                    "
                  />
                  <span>满意</span>
                </div>
                <div class="" style="margin-left: 30px" @click="change2(index)">
                  <img
                    :src="
                      infoValidateForm.list[index].isRight == 1
                        ? badIcon1
                        : badIcon2
                    "
                  />
                  <span>不满意</span>
                </div>
              </div>
            </div>
          </el-card>
          <div class="btnbox">
            <el-button type="primary" @click="submit">确认提交</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
export default {
  data() {
    return {
      dialogVisible: false,
      infoValidateForm: {
        companyJobId: "",
        userId: "",
        userJobId: "",
        userWorkId: "",
        list: [],
      },
      goodIcon1: require("@/assets/images/person/xiao-grey.png"),
      goodIcon2: require("@/assets/images/person/xiao-orage.png"),
      badIcon1: require("@/assets/images/person/sq-hui.png"),
      badIcon2: require("@/assets/images/person/sq-grey.png"),
      list: [
        // {id:1,icon1:require('@/assets/images/person/xiao-grey.png'),icon2:require('@/assets/images/person/sq-hui.png')},
        // {id:2,icon1:require('@/assets/images/person/xiao-grey.png'),icon2:require('@/assets/images/person/sq-hui.png')},
        // {id:3,icon1:require('@/assets/images/person/xiao-grey.png'),icon2:require('@/assets/images/person/sq-hui.png')},
      ],
    };
  },
  activated() {
    this.getList();
  },
  mounted() {
    Bus.$on("evaluatestuts", (data) => {
      // console.log('退场接受到', data);
      this.dialogVisible = data.show;
      this.infoValidateForm.userWorkId = data.id;
      this.infoValidateForm.companyJobId = data.companyJobId;
      this.infoValidateForm.userId = data.userId;
      this.infoValidateForm.userJobId = data.userJobId;
    });
  },
  methods: {
    getList() {
      this.$api.getdict("get", { code: "score_option" }).then((res) => {
        this.list = res.data;
        let arr = [];
        res.data.map((item) => {
          let obj = {
            dictId: item.id,
            isRight: 1,
          };
          arr.push(obj);
        });
        this.infoValidateForm.list = arr;
      });
    },
    closebox() {
      this.dialogVisible = false;
      // Bus.$emit('reportdstuts', false)
    },
    submit() {
      this.$api.workScore("post", this.infoValidateForm).then(() => {
        this.dialogVisible = false;
        this.$message({
          message: "操作成功",
          type: "success",
        });

        window.location.reload();
      });
    },
    change1(index) {
      this.infoValidateForm.list[index].isRight = 1;
    },
    change2(index) {
      this.infoValidateForm.list[index].isRight = 0;
    },
  },
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
</style>
<style lang="less" scoped>
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
.details {
  padding-bottom: 20px;
  .wb-tit {
    padding: 17px 0;
    text-align: center;
    background-color: #f3f3f3;
    color: #000000;
    font-weight: 600;
  }
}

.updatabox {
  padding: 30px 50px;
  /deep/ .el-button {
    border-radius: 0px;
    width: 430px;
    margin-top: 50px;
    background: #00bcff;
    border: none;
  }
  .btnbox {
    text-align: center;
  }
  .box-card {
    margin-bottom: 20px;
    /deep/.el-card__body {
      padding: 12px 20px;
    }
    .list {
      display: flex;
      align-items: center;
      .l-left {
        display: flex;
        line-height: 1.8;
        flex-direction: column;
        flex: 1;
        color: #111111;
        div:last-child {
          color: #666666;
        }
      }
      .l-right {
        width: 230px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        div:hover {
          cursor: pointer;
        }
        div {
          display: flex;
          align-items: center;
          color: #000000;
          img {
            width: 30px;
            height: 30px;
            margin-right: 6px;
          }
        }
      }
    }
  }
}
</style>
